import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import UseCaseSider from "../../components/useCases/use-cases-sider";

const UseCaseTaskManager = ({}) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Online Task Management</title>
      <meta
        name="description"
        content="Looking for an Online Task Management Tool. You need something efficient and something which you can add enough information to each task - DayViewer does that. It is an online task management system unlike others has a capable calendar system and planner."
      />
    </Helmet>
    <Layout>
      <CommonHeader />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <UseCaseSider />

        <Layout className="guide-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Easy To Use Task Management App</h1>

            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                Sign Up
              </a>
            </Button>
          </div>

          <Row>
            <div>
              <h2>
                Tasks take up your time. It makes sense to manage everything
                together in one place with DayViewer.
              </h2>
            </div>
            <Col>
              <p>
                DayViewer may be used as your main task management system. Tasks
                in DayViewer have built in features which allow you to set
                progress, priority and checklists. For team tasks, you can
                assign tasks to individuals in your team.
              </p>
              <p>
                Our tasks solution provides multiple views and tools to see your
                tasks with clarity, including timeline, cards and calendar
                views.
              </p>
              <p>
                Additionally tasks have their own dedicated section in DayViewer
                whereby you can easily filter tasks by progress.
              </p>
              <p>
                Tasks in DayViewer also have an overdue feature - such that if a
                task goes overdue, it is clearly highlighted on the task card,
                and in your overdue tasks list on your dashboard.
              </p>
              <p>
                Task management isn't just important for work management, but
                can be very useful in day to day planning, personal life and
                while studying. Creating entries you need to get done as tasks
                can provide that extra motivation needed to get things done on
                schedule.
              </p>
              <p>
                Not only is our task management system flexible for simple task
                reminders, but you can manage more complicated tasks and
                projects by adding checklists and by organizing with our labels
                feature.
              </p>
              <p>
                Time and task management go hand in hand, additionally, we have
                provided you with the facility to create richer task entries. We
                believe it's more efficient if you keep all your information
                associated with your task together in one place. This is why we
                say DayViewer is much more than just a calendar or planner, it
                is a "full spectrum information organizer and management
                system".
              </p>
              <p>
                Tasks viewable in your calendar make it much easier to plan,
                organize and prioritize everything in your schedule. View
                everything with clarity when you manage tasks.
              </p>

              <div
                style={{
                  textAlign: "center",
                  padding: 20
                }}
              >
                <img
                  src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/heroslides/dv-online-tasks.png"
                  alt="online task management"
                  title="online task management"
                />
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default UseCaseTaskManager;
